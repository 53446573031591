<script>
/* eslint-disable */
</script>
<template>
  <b-card>
    <b-form-file
      ref="csvFileRef"
      v-model="file"
      @input="changeToJSON"
      multiple
      size="lg"
      accept=".csv"
      class="mb-5 drop-zone p-0"
    >
      <template slot="drop-placeholder">
        <b-row class="w-100 h-100 align-content-center position-absolute">
          <b-col class="d-flex flex-column align-items-center">
            <b-img
              :src="require('@/@core/assets/iconpro/cloud-arrow-up.svg')"
              width="70px"
              height="70px"
            />
            <h4>Trascina i Tuoi file qui</h4>
            <div>
            </div>
          </b-col>
        </b-row>
      </template>
      <template slot="placeholder">
        <b-row class="w-100 h-100 align-content-center position-absolute">
          <b-col class="d-flex flex-column align-items-center">
            <b-img
              :src="require('@/@core/assets/iconpro/cloud-arrow-up.svg')"
              width="70px"
              height="70px"
            />
            <h4>Trascina i Tuoi file qui...</h4>
            <h4>Oppure clicca qui</h4>
          </b-col>
        </b-row>
      </template>
    </b-form-file>
    {{ arrayCSV }}
  </b-card>
</template>

<script>
// import csvJSON from '@core/fromCSVtoJson/csv2Json'
// import readCSV from '@core/fromCSVtoJson/csv2Json'
import {
  BCard, BFormFile, BRow, BCol, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BFormFile,
    BCol,
    BImg,
  },
  data() {
    return {
      file: [],
      arrayCSV: [],
    }
  },
  methods: {
    changeToJSON(event) {
      event.forEach(el => {
        let csv = ''
        let reader = new FileReader()
        reader.readAsText(el)
        reader.onload = evt => {
          csv = evt.target.result
          const csvConvertedToJSON = this.csvJSON(csv)
          // console.log(csvConvertedToJSON)
          this.arrayCSV.push(csvConvertedToJSON)
        }
      })
      this.file.splice(0)
    },
    csvJSON(csvText) {
      const lines = []
      const linesArray = csvText.split('\n')
      // for trimming and deleting extra space
      linesArray.forEach(e => {
        const row = e.replace(/[\s]+[,]+|[,]+[\s]+/g, ',').trim()
        lines.push(row)
      })
      // for removing empty record
      lines.splice(lines.length - 1, 1)
      const result = []
      const headers = lines[0].split(',')
      for (let i = 1; i < lines.length; i += 1) {
        const obj = {}
        const currentline = lines[i].split(',')
        for (let j = 0; j < headers.length; j += 1) {
          obj[headers[j]] = currentline[j]
        }
        result.push(obj)
      }
      // return result; //JavaScript object
      // return JSON.stringify(result); //JSON
      return result
    },
  },
}
</script>

<style>
.control-section {
    height: 100%;
    min-height: 240px;
}
label.custom-file-label {
   background: #f8f8f8 !important;

}
.drop-zone {
  background: #f8f8f8 !important;
  border: 2px dashed #7367f0;
  min-height: 250px;
  width:100%;
  content: 'ciao'
}

.b-form-file.b-custom-control-lg .custom-file-label::after {
    height: 44px;
    visibility: hidden;
}
.myVisibility{
  visibility: hidden;
}

</style>
